/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import Layout from '@organisms/layout'
import SEO from '@atoms/seo'
import { useComponentVisible } from '@particles/hooks'
import Text from '@particles/text'
import Heading from '@particles/heading'
import Tags from '@molecules/tags'
import Link from '@atoms/link'
import Visualization from '@organisms/visualization'
import Formula from '@atoms/formula'
import SourceHTML from '@particles/source-html'
import Image from '@atoms/image'
import { media } from '@breakpoints'
import TrackMetric from '@organisms/track-metric'
import MetricTemplate from '@molecules/metric-template'
import Youtube from '@atoms/youtube'
import Faq from '@molecules/faq'
import Contributor from '@molecules/contributor'
import StyledTOCSubnav from '@molecules/table-of-contents-subnav'
import VisualizationModal from '@organisms/visualization-modal'
import ScrollComponent from '@organisms/scroll-component'
import Breadcrumbs from '@molecules/breadcrumbs'
import DbtButton from '@atoms/metric-toolkit/dbt-button'
import CtaButton from '@atoms/metric-toolkit/cta-button'
import LiveEmbed from '@molecules/live-embed'

const MetricPageLayout = styled(Layout)`
  ${media.desktop_small`
    .responsive-grid {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
    #side-bar, #toolkit {
      width: auto;
    }
  `}
  .metric-text {
    font-size: 1rem;
    line-height: 2;
  }

  background: #f6f8fe;
  ${media.desktop_medium_up`
      main {
        max-width: 1280px;
      }

      .responsive-grid {
        grid-template-columns: 900px 292px;
      }
    `}
  #side-bar, #toolkit {
    padding: 1.5rem;
  }
`

const ToolkitButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`

const StyledBody = styled.div`
  ${Text} {
    margin-top: 1rem;
  }
  ${Text}:first-child {
    margin-top: 0;
  }
`

const StyledBenchmark = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  min-width: min(500px, calc(100vw - 2rem));

  img {
    border: 1px solid #d7dcea;
  }
`

const SideBarContainer = styled(Layout.Column)`
  gap: 1rem;
`

const SideBar = styled(Layout.Column)`
  display: flex;

  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  padding: 1rem;

  ${Heading} {
    margin-top: 3.5rem;
    margin-bottom: 1rem;
  }

  ${Heading}:first-child {
    margin-top: 0;
  }
`

const MetricInfo = styled.a`
  text-decoration: none;
  position: relative;
  cursor: pointer;
  display: inline-block;
  line-height: 200%;

  span {
    color: white;
    padding: 0.25rem 0.5rem;
    font-weight: 600;
    border-radius: 0.5rem;
    margin: 0 0.25rem;
    background: ${({ theme }) => theme.palette.primary['400']};
  }

  &:hover {
    div {
      opacity: 1;
    }
  }

  div {
    transition: opacity 0.2s ease-in-out;
    color: black;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 2rem;
    left: 0;
    background: ${({ theme }) => theme.palette.text['300']};
    border-radius: 0.5rem;
    padding: 1rem;
    color: white;
    width: 500px;
    line-height: 140%;
    box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
    z-index: 1;
  }
`

const RenderFormula = ({ formulas, metrics }) => {
  const RenderMetrics = ({ formula, metrics }) => {
    const formulaParts = []

    metrics?.forEach((metric) => {
      const regex = new RegExp(metric.text, 'g')
      formula = formula.replace(regex, (match) => {
        formulaParts.push({
          text: match,
          metric: metric.metric
        })
        return `|||${formulaParts.length - 1}|||`
      })
    })

    const parts = formula.split('|||')

    return (
      <Formula>
        {parts.map((part, index) =>
          formulaParts[part] ? (
            <MetricInfo
              key={`metric-info-${index}`}
              href={`${formulaParts[part].metric.slug}`}
            >
              <span>{formulaParts[part].text}</span>
              <div>
                <b>Description:</b> {formulaParts[part].metric.description}
              </div>
            </MetricInfo>
          ) : (
            part
          )
        )}
      </Formula>
    )
  }

  return formulas.map((formulaObj, index) => {
    const formula = formulaObj.formula
    return <RenderMetrics key={index} formula={formula} metrics={metrics} />
  })
}

const MetricPage = ({ data, location }) => {
  const metric = data.metric
  const seed = '1234'
  var tags = []
  const category = {
    name: metric.category[0]?.name || 'SaaS',
    slug: metric.category[0]?.slug || '/category/saas'
  }

  const formulaContext = metric.formula_context

  const crumbs = [
    { text: 'All Metrics', to: '/all-metrics', active: true },
    { text: `${category.name} Metrics`, to: category.slug, active: true },
    { text: metric.name, to: '#', active: false }
  ]

  const templates = metric.instant_metrics
  metric.category?.forEach((v) => {
    tags.push({ name: v.name, link: v.slug })
  })

  metric.role?.forEach((v) => {
    tags.push({ name: v.name, link: v.slug })
  })

  let knownServices = {}
  templates?.forEach((v) => {
    if (knownServices[v.service.name]) return
    knownServices[v.service.name] = 1
    tags.push({
      name: v.service.name,
      link: v.service.slug
    })
  })

  const hasCharts = metric.chart && metric.chart.length > 0

  // const calculateScroll = (ref, left) => {
  //   const current = ref.current.scrollLeft
  //   const bounds = ref.current.getBoundingClientRect()
  //   ref.current.scrollLeft = left - bounds.left + current;
  // }

  const {
    ref: vizModalRef,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false, true)

  const [modalTitle, setModalTitle] = useState(metric.name)
  const [modalData, setModalData] = useState(metric.chart?.[0])

  const name = metric.abbreviation
    ? `${metric.name} (${metric.abbreviation})`
    : metric.name

  return (
    <MetricPageLayout location={location} maxMedia={'desktop_medium'}>
      <SEO title={name} location={location} description={metric.summary} />
      <StyledTOCSubnav className={'content-sub-nav'} />
      <Layout.Grid
        gap={3.5}
        className={'responsive-grid'}
        gridTemplate={'auto 292px'}
        margin={'R0 B0 L0 T6'}
        padding={'T1'}
      >
        <Layout.Column>
          <Breadcrumbs crumbs={crumbs} />
          <Heading h1 margin={'T1 B0.5'}>
            {name}
          </Heading>
          {(metric.date_created || metric.date_updated) && (
            <Text size={0.75} bold color={'300'}>
              Last updated: {metric.date_updated ?? metric.date_created}
            </Text>
          )}
          <Heading
            id="metric-title"
            h3
            as="h2"
            toc={'Metric definition'}
            margin={'T4 B1.5'}
          >
            What is {metric.name}?&nbsp;
          </Heading>
          <Text className={'metric-text'}>{metric.description}</Text>
          {metric.altername_name && metric.altername_name.length > 0 && (
            <Text as="span" margin={'T1'}>
              Alternate names:{' '}
              {metric.altername_name.map((v, i) =>
                i !== metric.altername_name.length - 1 ? v.name + ', ' : v.name
              )}
            </Text>
          )}
          {metric.youtube && metric.youtube.length > 0 && (
            <div
              id={'youtube-videos'}
              style={{ width: '100%', marginTop: '1.5rem' }}
            >
              {metric.youtube.map((vid, i) => {
                return <Youtube src={vid.link} key={`youtube-${i}`} />
              })}
            </div>
          )}
          {metric.formulas && (
            <Heading
              id="metric-formula"
              h4
              as="h3"
              margin={'T4.5 B1.5'}
              toc={'Formula'}
            >
              {metric.name} Formula
            </Heading>
          )}
          {metric.formulas && (
            <RenderFormula
              formulas={metric.formulas}
              metrics={formulaContext}
            />
          )}
          {metric.example && (
            <Heading
              id="metric-example"
              h4
              as="h3"
              margin={'T4.5 B1.5'}
              toc={'How to calculate'}
            >
              How to calculate {metric.name}
            </Heading>
          )}
          {metric.example && (
            <Text className={'metric-text'}>{metric.example}</Text>
          )}

          {metric.live_embed && (
            <>
              <Heading
                id="metric-live-embed"
                h4
                as="h3"
                margin={'T4.5 B1.5'}
                toc={'Live embedding'}
              >
                Explore {metric.name} sample data
              </Heading>
              <Text style={{ alignSelf: 'start' }} margin={'B1'}>
                This visualization is a live embed from{' '}
                <Link
                  target="_blank"
                  href="https://www.klipfolio.com/powermetrics"
                >
                  Klipfolio PowerMetrics.
                </Link>
              </Text>
            </>
          )}

          {metric.live_embed && <LiveEmbed liveEmbed={metric.live_embed} />}

          <TrackMetric globalMetric={metric} margin={'T4'}>
            {templates &&
              templates.map((template, i) => (
                <MetricTemplate //TODO: Templates
                  key={`template-${i}`}
                  showLabel={templates.length <= 2}
                  service={template.service.name}
                  link={template.url}
                  pageSource={location.pathname}
                />
              ))}
          </TrackMetric>

          {metric.benchmark && metric.benchmark.trim() !== '' && (
            <Heading
              id="metric-benchmark"
              h4
              as="h3"
              toc={'Benchmark'}
              margin={'T4.5 B1.5'}
            >
              What is a good {metric.name} benchmark?
            </Heading>
          )}

          {metric.benchmark && metric.benchmark.trim() && (
            <Text className={'metric-text'}>{metric.benchmark}</Text>
          )}

          {metric.benchmark_images && metric.benchmark_images.length > 0 && (
            <Heading id="metric-benchmark-example" h4 as="h3" margin={'T4.5'}>
              {metric.name} benchmarks
            </Heading>
          )}

          {metric.benchmark_images && metric.benchmark_images.length > 0 && (
            <ScrollComponent>
              {metric.benchmark_images.map((image, i) => {
                return (
                  <Layout.Column margin={'R1.5rem'} key={`image-heading-${i}`}>
                    <StyledBenchmark style={{ width: '100%' }}>
                      {image.name && <Text margin={'B1.5'}>{image.name}</Text>}
                      <Image
                        file={image.image}
                        typeof="foaf:Image"
                        height={'272px'}
                        width={'436px'}
                        style={{ width: '100%', height: '272px' }}
                        alt={image.image?.title}
                      />
                    </StyledBenchmark>
                    {image.link && (
                      <Link newtab href={image.link} margin={'T1.5rem'}>
                        {image.text}
                      </Link>
                    )}
                  </Layout.Column>
                )
              })}
            </ScrollComponent>
          )}

          {hasCharts && (
            <Heading
              id="metric-viz"
              h4
              as="h3"
              toc={'Visualization examples'}
              margin={'T4.5 B1.5'}
            >
              How to visualize {metric.name}?
            </Heading>
          )}
          {hasCharts && metric.chart_description && (
            <Text className={'metric-text'}>{metric.chart_description}</Text>
          )}
          {hasCharts && (
            <Heading id="metric-viz" h4 as="h3" margin={'T4.5'}>
              {metric.name} visualization example
              {metric.chart.length > 1 ? 's' : ''}
            </Heading>
          )}
          {hasCharts && (
            <>
              <ScrollComponent margin={'B3.5'}>
                {metric.chart.map((chart, i) => {
                  return (
                    <Visualization
                      key={`chart-${i}`}
                      setIsComponentVisible={setIsComponentVisible}
                      title={metric.name}
                      data={chart}
                      seed={seed}
                      setData={() => setModalData(metric.chart[i])}
                      setTitle={setModalTitle}
                    />
                  )
                })}
              </ScrollComponent>
              <VisualizationModal
                isOpen={isComponentVisible}
                forwardRef={vizModalRef}
                title={modalTitle}
                seed={seed}
                data={modalData}
              />
            </>
          )}
          {metric.more_section && (
            <Heading
              id="metric-more-about"
              h3
              as="h2"
              margin={'T4.5 B1.5'}
              toc={'In depth'}
            >
              More about {metric.name}
            </Heading>
          )}
          {metric.more_section && (
            <StyledBody>
              <SourceHTML
                html={metric.more_section}
                className={'metric-text'}
              />
            </StyledBody>
          )}

          {metric.faqs && metric.faqs.length > 0 && (
            <Heading id="FAQ" h3 as="h2" toc={'FAQ'} margin={'T4.5 B1.5'}>
              {metric.name} Frequently Asked Questions
            </Heading>
          )}

          {metric.faqs &&
            metric.faqs.length > 0 &&
            metric.faqs.map((faq, i) => (
              <Faq key={`faq-${i}`} title={faq.question} body={faq.answer} />
            ))}

          {/* {metric.field_youtube_iframe && metric.field_youtube_iframe.length > 0 && <Heading h3 margin={"T2.5 B1.5"}>Youtube Video{metric.field_youtube_iframe.length > 1 ? "s" : ""}</Heading>} */}

          {metric.external_resources && metric.external_resources.length > 0 && (
            <Heading
              h4
              as="h3"
              id={'additional-content'}
              toc={'Additional Content'}
              margin={'T4.5 B1.5'}
            >
              Recommended resources related to {metric.name}
            </Heading>
          )}
          {metric.external_resources &&
            metric.external_resources.map((resource, i) => {
              return (
                <Link
                  key={`additional-${i}`}
                  margin={'B1'}
                  newtab
                  href={resource.url}
                >
                  {resource.text}
                </Link>
              )
            })}
        </Layout.Column>
        <SideBarContainer style={{ marginTop: '3.5rem' }}>
          {/* <MetricStack location={location} pageName={metric.title} /> */}
          <SideBar id={'toolkit'}>
            <Heading h4>Metric Toolkit</Heading>
            <ToolkitButtons>
              <DbtButton metric={metric} />
              <CtaButton
                metric={metric}
                templates={templates}
                location={location}
              />
            </ToolkitButtons>
          </SideBar>
          <SideBar id={'side-bar'}>
            {metric.contributor && metric.contributor.length > 0 && (
              <Heading h4>
                Contributor
                {metric.contributor.length > 1 && 's'}
              </Heading>
            )}

            {metric.contributor &&
              metric.contributor.map((contrib, i) => {
                return (
                  <Contributor
                    key={`contribute-${i}`}
                    name={contrib.name}
                    subtitle={contrib.subtitle}
                    twitter={contrib.twitter}
                    linkedin={contrib.linkedin}
                    // web={contrib.field_link && contrib.field_link.uri}
                    web={`/contributor/${contrib.name
                      .toLowerCase()
                      .replace(/ /g, '_')}`}
                    imageFile={contrib.image}
                    imageAlt={contrib.image?.title ?? 'Contributor Image'}
                  />
                )
              })}

            {metric.podcast_link && <Heading h4>Podcast Episode</Heading>}

            {metric.podcast_link && (
              <Contributor
                name={`Metric Stack`}
                subtitle={metric.name}
                web={metric.podcast_link}
                imageFile={data.podcastLogo}
                imageAlt={'Metric Stack Podcast'}
                radius="0.5rem"
              />
            )}

            {metric.related_metrics?.length != 0 && (
              <>
                <Heading h4 as="h3">
                  Metrics related to {metric.name}
                </Heading>
                <Layout.Grid gap={1}>
                  {metric.related_metrics.map((relatedMetric, i) => (
                    <Link key={`related-${i}`} to={relatedMetric.slug}>
                      {relatedMetric.name}
                    </Link>
                  ))}
                </Layout.Grid>
              </>
            )}

            {metric.comparisons?.length != 0 && (
              <>
                <Heading h4>Comparisons</Heading>
                <Layout.Grid gap={1}>
                  {metric.comparisons.map((relatedMetric, i) => (
                    <Link
                      key={`compare-${i}`}
                      to={relatedMetric.slug}
                      margin={'B0'}
                    >
                      {relatedMetric.title}
                    </Link>
                  ))}
                </Layout.Grid>
              </>
            )}

            <Heading h4 as="h3">
              Related Tags
            </Heading>
            <Tags wrap={'left'} tags={tags} size={0.9} outlined />
          </SideBar>
        </SideBarContainer>
      </Layout.Grid>
    </MetricPageLayout>
  )
}
export default MetricPage

export const pageQuery = graphql`
  query MetricsPageQuery($directus_id: String!, $metricsHQId: String) {
    site {
      siteMetadata {
        algolia_index
      }
    }
    dimensions: metricDimensions(metric_id: { eq: $metricsHQId }) {
      dimensions
    }
    podcastLogo: directusFiles(
      directus_id: { eq: "bf0e2644-b3c7-4d8f-b0ba-780317723e99" }
    ) {
      cdn
    }
    chart: charts(directus_id: { eq: "4f480c09-ca2f-48bb-b4d1-477b40f60229" }) {
      ...ChartsFragment
    }
    metric: metric(directus_id: { eq: $directus_id }) {
      id
      directus_id
      alternate_name {
        name
      }
      abbreviation
      benchmark
      benchmark_images {
        name
        image {
          cdn
          placeholder
        }
        link
        text
      }
      category {
        name
        slug
      }
      chart {
        ...ChartsFragment
      }
      live_embed {
        metrics
        vis_spec
        readonly
        showpropspanel
        name
      }
      chart_description
      comparisons {
        slug
        title
      }
      contributor {
        name
        linkedin
        twitter
        subtitle
        image {
          cdn
          placeholder
        }
      }
      date_created(formatString: "MMM DD, YYYY")
      date_updated(formatString: "MMM DD, YYYY")
      instant_metrics {
        service {
          slug
          name
        }
        url
      }
      description
      example
      external_resources {
        url
        text
      }
      faqs {
        question
        answer
      }
      formulas {
        formula
      }
      formula_context {
        text
        metric {
          name
          description
          slug
        }
      }
      more_section
      name
      related_metrics {
        slug
        name
        description
      }
      role {
        slug
        name
      }
      slug
      status
      summary
      podcast_link
    }
  }

  fragment ChartsFragment on Charts {
    y_axis_suffix
    y_axis_range_min
    y_axis_range_max
    y_axis_prefix
    y_axis_label
    y_axis_decimals
    y_axis
    x_axis_length
    x_axis_label
    x_axis_custom
    x_axis
    type
    trendline
    status
    seo_image {
      cdn
    }
    positive_trend
    directus_id
    label
    description
  }
`
